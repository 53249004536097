
























































































































































import { Component, Vue } from 'vue-property-decorator'
import { GiftCardSeller, GiftCard, Seller, Wholesaler } from '@/models/index'

import Pagination from '@/components/Pagination/index.vue'

@Component({
  name: 'GiftCardSellersTable',
  components: {
    Pagination,
    GiftCardSelect: () => import('@/components/GiftCardSelect/index.vue'),
    GiftCardSellerDialog: () => import('./components/GiftCardSellerDialog.vue')
  }
})
export default class extends Vue {
  giftCardSellers: GiftCardSeller[] = []
  giftCardSellersLoading = true
  totalGiftCardSellers = 0

  giftCards: GiftCard[] = []
  giftCardsLoading = true

  sellers: Seller[] = []
  sellersLoading = true

  apiClients: any[] = []

  wholesalers: Wholesaler[] = []
  wholesalersLoading = true

  tempData: GiftCardSeller | null = null
  dialogVisible = false

  listQuery = {
    page: 1,
    limit: 20,
    name: undefined,
    sellerId: '',
    giftCardId: '',
    wholesalerId: '',
    apiClient: ''
  }

  get loading() {
    return this.giftCardsLoading && this.sellersLoading && this.wholesalersLoading
  }

  created(): void {
    this.getGiftCardSellers()

    this.getSellers()
    this.getGiftCards()
    this.getWholesalers()
  }

  async getGiftCardSellers(): Promise<void> {
    this.giftCardSellersLoading = true

    const { data, meta } =
      await GiftCardSeller
        .includes([{ giftCard: 'skus' }, 'seller', 'wholesaler'])
        .where({
          sellerId: this.listQuery.sellerId,
          giftCardId: this.listQuery.giftCardId,
          wholesalerId: this.listQuery.wholesalerId,
          byGiftCardNameAlphabetical: true,
          apiClient: this.listQuery.apiClient
        })
        .select({
          giftCard: ['name', 'skus'],
          seller: ['name'],
          wholesaler: ['name']
        })
        .page(this.listQuery.page)
        .per(this.listQuery.limit)
        .stats({ total: 'count' })
        .all()

    this.giftCardSellers = data
    this.totalGiftCardSellers = meta.stats.total.count
    this.giftCardSellersLoading = false
  }

  async getSellers(): Promise<void> {
    const { data } = await Seller
        .select(['name', 'apiClient'])
        .order({ name: 'asc' })
        .page(1)
        .per(999)
        .all()

    this.sellers = data
    this.getApiClients()
    this.sellersLoading = false
  }

  getApiClients(): void {
    const apiClientsSet = new Set()

    this.sellers.forEach(seller => {
      if (seller.apiClient) apiClientsSet.add(seller.apiClient)
    })

    this.apiClients = Array.from(apiClientsSet).sort()
  }

  async getGiftCards(): Promise<void> {
    const { data } = await GiftCard
        .select(['name'])
        .order({ name: 'asc' })
        .page(1)
        .per(999)
        .all()

    this.giftCards = data
    this.giftCardsLoading = false
  }

  async getWholesalers(): Promise<void> {
    const { data } = await Wholesaler
        .select(['name'])
        .order({ name: 'asc' })
        .page(1)
        .per(999)
        .all()

    this.wholesalers = data
    this.wholesalersLoading = false
  }

  handleGiftCardSelect(giftCardId: string): void {
    this.listQuery.giftCardId = giftCardId
    this.handleFilter()
  }

  handleFilter(): void {
    this.listQuery.page = 1
    this.getGiftCardSellers()
  }

  handleCreate(giftCardSeller: GiftCardSeller): void {
    this.giftCardSellers.unshift(giftCardSeller)
    this.totalGiftCardSellers += 1
    this.dialogVisible = false
  }

  handleUpdate(giftCardSeller: GiftCardSeller): void {
    this.giftCardSellers.splice(this.giftCardSellers.findIndex(e => e.id === giftCardSeller.id), 1, giftCardSeller)
    this.dialogVisible = false
  }

  async handleDelete(data: GiftCardSeller, index: number): Promise<void> {
    try {
      await data.destroy()

      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })

      this.giftCardSellers.splice(index, 1)
      this.totalGiftCardSellers -= 1
    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          this.$notify({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
    }
  }
}
