



































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GiftCardSeller, GiftCard, Seller, Wholesaler, GiftCardSellerSku, Sku } from '@/models/index'

import { Form } from 'element-ui'

@Component({
  name: 'GiftCardSellerDialog',
  components: {
    SetMetadata: () => import('./SetMetadata.vue')
  }
})
export default class extends Vue {
  @Prop({ required: true }) private giftCards!: GiftCard[]
  @Prop({ required: true }) private sellers!: Seller[]
  @Prop({ required: true }) private wholesalers!: Wholesaler[]
  @Prop({ required: false, default: false }) private manualVisible!: boolean
  @Prop({ required: true }) private giftCardSeller!: GiftCardSeller | null

  visible = false

  dialogStatus = ''
  loading = false
  textMap = {
    update: 'Edit',
    create: 'Create'
  }

  skus: Sku[] = []
  loadingSkus = false

  rules = {
    buyer: [{ required: true, message: 'buyer is required', trigger: 'change' }],
    seller: [{ required: true, message: 'seller is required', trigger: 'change' }],
    sellerRate: [{ required: true, message: 'seller rate is required', trigger: 'change' }]
  }

  tempData = new GiftCardSeller({
    giftCard: new GiftCard({ id: '' }),
    seller: new Seller({ id: '' }),
    wholesaler: new Wholesaler({ id: '' }),
    default: true,
    metadata: {}
  })

  retrievalModeOptions = [
    {
      name: 'File',
      value: 'file_retrieval'
    },
    {
      name: 'Api',
      value: 'api_retrieval'
    },
    {
      name: 'Physical',
      value: 'physical_retrieval'
    }
  ]

  @Watch('visible', { immediate: true })
  onVisibleChange(visible: boolean): void {
    if (visible) !this.giftCardSeller ? this.handleCreate() : this.handleUpdate()
  }

  @Watch('manualVisible')
  setVisibility(visible: boolean): void {
    this.visible = visible
  }

  get isGiftCardSellerCreated(): boolean {
    return this.tempData.isPersisted
  }

  async getSkus(): Promise<void> {
    this.loadingSkus = true

    const { data } = await Sku
        .includes('giftCardSellerSkus')
        .where({ giftCardId: this.tempData.giftCard.id })
        .merge({ giftCardSellerSkus: GiftCardSellerSku.where({ giftCardSellerId: this.tempData.id }) })
        .page(1)
        .per(999)
        .all()

    this.skus = data.slice().filter((sku: Sku) => !sku.discardedAt).sort((a, b) => (a.faceValue ?? 0) - (b.faceValue ?? 0))
    this.loadingSkus = false
  }

  async handleCreate(): Promise<void> {
    this.resetTempData()
    await this.getSkus()
    this.dialogStatus = 'create'

    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  async handleUpdate(): Promise<void> {
    this.tempData = this.giftCardSeller!.dup()
    await this.getSkus()
    this.dialogStatus = 'update'

    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  resetTempData(): void {
    this.tempData = new GiftCardSeller({
      giftCard: this.giftCards[0].dup(),
      seller: this.sellers[0].dup(),
      wholesaler: this.wholesalers[0].dup(),
      default: true,
      metadataPerSku: false,
      metadata: {}
    })
  }

  async createData(): Promise<void> {
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        this.loading = true
        const data = this.tempData

        try {
          await data.save({ with: ['seller', 'wholesaler', 'giftCard'] })

          this.$notify({
            title: 'Gift Card Seller created',
            message: 'Gift Card Seller successfully created',
            type: 'success',
            duration: 2000
          })

          this.$emit('created', data)
          if (!this.tempData.metadataPerSku) this.visible = false
          else await this.getSkus()
        } catch (e) {
          for (const error of (e as any).response.data.errors) {
            if (error) {
              this.$notify({
                title: error.title,
                message: error.detail,
                type: 'error',
                duration: 5000
              })
            }
          }
          this.loading = false
        }
        this.loading = false
      }
    })
  }

  async updateData(): Promise<void> {
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        this.loading = true
        const data = this.tempData

        try {
          await data.save({ with: ['buyer', 'seller', 'sellerRate', 'wholesalerRate'] })

          this.$notify({
            title: 'Gift Card Seller Updated',
            message: 'Gift Card Seller successfully updated',
            type: 'success',
            duration: 2000
          })

          this.$emit('updated', data)
          this.$emit('close')
        } catch (e) {
          for (const error of (e as any).response.data.errors) {
            if (error) {
              this.$notify({
                title: error.title,
                message: error.detail,
                type: 'error',
                duration: 5000
              })
            }
          }
          this.loading = false
        }
        this.loading = false
      }
    })
  }
}
