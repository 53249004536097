


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GiftCardSeller, GiftCardSellerSku, Sku } from '@/models'

@Component({
  name: 'SetMetadata'
})
export default class extends Vue {
  @Prop({ required: false }) private giftCardSeller!: GiftCardSeller
  @Prop({ required: false }) private giftCardSellerSkus!: GiftCardSellerSku[]
  @Prop({ required: false }) private sku!: Sku
  @Prop({ required: false }) private metadata!: any
  @Prop({ required: false }) private loading!: boolean
  @Prop({ required: false, default: false }) private modeSku!: boolean

  createOrUpdateLoading = false
  tempMetadata: any = {}
  newMetadataKey = ''
  newMetadataValue = ''

  @Watch('metadata', { immediate: true })
  setMetadata(): void {
    if (!this.modeSku) {
      this.tempMetadata = this.metadata
      this.newMetadataKey = ''
      this.newMetadataValue = ''
    }
  }

  @Watch('sku', { immediate: true })
  setMetadataPerSku(): void {
    if (this.modeSku) {
      this.tempMetadata = this.sku?.giftCardSellerSkus[0]?.metadata || {}
      this.newMetadataKey = ''
      this.newMetadataValue = ''
    }
  }

  createMetadata(): void {
    this.$set(this.tempMetadata, this.newMetadataKey, this.newMetadataValue)
    if (!this.modeSku) this.$emit('change', this.tempMetadata)
  }

  deleteMetadata(key: string): void {
    this.$delete(this.tempMetadata, key)
    if (!this.modeSku) this.$emit('change', this.tempMetadata)
  }

  async createOrUpdate() {
    this.createOrUpdateLoading = true

    try {
      if (this.giftCardSellerSkus.length === 0) {
        const data = new GiftCardSellerSku({
          giftCardSeller: this.giftCardSeller,
          sku: this.sku,
          metadata: this.tempMetadata
        })

        await data.save({ with: ['sku.id', 'giftCardSeller.id'] })
      } else {
        let data = this.giftCardSellerSkus[0]
        data.metadata = this.tempMetadata

        await data.save()
      }

      this.$notify({
        title: 'Gift Card Seller sku created',
        message: 'Gift Card Seller sku successfully created',
        type: 'success',
        duration: 2000
      })

    } catch (e) {
      for (const error of (e as any).response.data.errors) {
        if (error) {
          this.$notify({
            title: error.title,
            message: error.detail,
            type: 'error',
            duration: 5000
          })
        }
      }
      this.createOrUpdateLoading = false
    }
    this.createOrUpdateLoading = false
  }
}
