import { render, staticRenderFns } from "./GiftCardSellerDialog.vue?vue&type=template&id=6f52953a&"
import script from "./GiftCardSellerDialog.vue?vue&type=script&lang=ts&"
export * from "./GiftCardSellerDialog.vue?vue&type=script&lang=ts&"
import style0 from "./GiftCardSellerDialog.vue?vue&type=style&index=0&id=6f52953a&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports